const resource = {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estaciones"])},
    "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenedores"])},
    "samplingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de monitorización"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de usuarios"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de parámetros"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de alertas"])},
    "opendata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Data"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga manual de imágenes"])},
    "saica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAICA"])},
    "saih": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAIH"])},
    "aemet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AEMET"])},
    "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graficar"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte"])},
    "satellite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satelital"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación de datos"])},
    "validateExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación de datos (Administrador)"])},
    "validateData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar datos"])},
    "uploaderData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar datos"])},
    "downloadImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar opticos"])},
    "marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcado"])},
    "markedImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes"])},
    "markedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos"])},
    "importData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación de datos"])},
    "graphData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de graficación"])},
    "downloadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar datos"])},
    "opticalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos ópticos"])},
    "trainingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos entrenamiento"])},
    "menuItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["componetes del menu"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "loadTrainingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar datos de entrenamiento"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria"])}
  },
  "title": {
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
    "dialogUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de parámetros"])},
    "dataValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de datos de validación"])},
    "sendManualCapture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar captura manual"])},
    "dataUploadInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información subida de datos"])},
    "colourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de colores"])},
    "downloadImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga de imágenes"])},
    "downloadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar de datos"])},
    "downloadTrainingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga de datos de entrenamiento"])},
    "samplingPointList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de puntos de monitorización"])},
    "uploadDataHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros detectados"])},
    "selectDataValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de datos de validación"])},
    "generateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generación de informes"])}
  },
  "subtitle": {
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
    "validationResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado de la comparativa"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el punto de monitorización sobre el que se quiere subir datos"])}
  },
  "label": {
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valores"])},
    "apikey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api key"])},
    "alertCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear alerta"])},
    "alertNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva alerta"])},
    "alertEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar alerta"])},
    "alertList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de alertas"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activadas"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivadas"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "assignRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignar rol"])},
    "aemetStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estacion aemet"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas"])},
    "all2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "aemetCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva AEMET"])},
    "aemetEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar AEMET"])},
    "anyStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier estación"])},
    "andOr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AND/OR"])},
    "absolute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolutas"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
    "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cámara"])},
    "colourAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar el color:"])},
    "samplingPointType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de punto de monitorizacion"])},
    "modelIa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de IA"])},
    "modelCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo cámaras"])},
    "colour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "sequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secuencial:"])},
    "labelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetado"])},
    "labellingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de etiquetado"])},
    "infoSamplingPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacion punto de monitorizacion"])},
    "hexadecimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hexadecimal"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "subtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtipo"])},
    "locality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidad"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "waterFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caudal (L/s)"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención"])},
    "mainInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
    "containerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contenedores"])},
    "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenedor"])},
    "containerEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar contenedor"])},
    "containerNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo contenedor"])},
    "identifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
    "dataEnvironment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos ambientales"])},
    "dataOperational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos operacionales"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuario"])},
    "userNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo usuario"])},
    "userEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar usuario"])},
    "userList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de usuarios"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "dataUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de usuario"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "titlePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar contraseña"])},
    "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
    "rol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de usuario"])},
    "population": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Población"])},
    "populationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de población"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición"])},
    "pouringType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de vertido"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "samplingPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de monitorización"])},
    "samplingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de monitorización"])},
    "samplingPointList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de puntos de monitorización"])},
    "samplingPointStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignar estaciones a un punto de monitorización"])},
    "samplingPointNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo punto de monitorización"])},
    "samplingPointEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar punto de monitorización"])},
    "samplingPointInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del punto de monitorización"])},
    "samplingPointName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre punto de monitorizacion"])},
    "loginWelcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a <strong>Weas</strong>"])},
    "loginWelcomeBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación para la monitorización de calidad de las aguas superficiales"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright ® ", _interpolate(_named("year")), " ", _interpolate(_named("logo")), " Todos los derechos reservados"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "codeFTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código FTP"])},
    "typeDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "receivingMedium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio receptor"])},
    "lightingSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuente de iluminación"])},
    "waterMovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimiento de agua"])},
    "nameContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del Contenedor:"])},
    "containerSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño recipiente"])},
    "angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo"])},
    "utmX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenada X"])},
    "utmY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenada Y"])},
    "timeZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona horaria"])},
    "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estación"])},
    "stationsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de estaciones"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
    "wakeTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de despertar"])},
    "sleepTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de dormir"])},
    "massCapture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["captura masiva"])},
    "functionMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo"])},
    "sendInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de datos"])},
    "bluetooth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bluetooth"])},
    "stationEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar estación"])},
    "stationNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva estación"])},
    "imageViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visor de imágenes"])},
    "originalImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen original"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros"])},
    "parametersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de parámetros"])},
    "parameterNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo parámetro"])},
    "parameterAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar parámetros"])},
    "parameterAssignClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar parámetros Visible Clientes"])},
    "parameterAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los parámetros"])},
    "parameterClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro clientes"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades"])},
    "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviatura"])},
    "instrumentationRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de instrumentación"])},
    "physicalRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango físico del parámetro"])},
    "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango"])},
    "precision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad"])},
    "availableParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros disponibles"])},
    "operational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacional"])},
    "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiental"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calidad del agua"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "meteorology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos meteorológicos"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moda"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "monitorizationPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de monitorización"])},
    "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
    "valueIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor IA"])},
    "oper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oper"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador"])},
    "compositeOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And/Or"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
    "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaz"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "rangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de fechas"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipio"])},
    "confederation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confederación"])},
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenadas"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio"])},
    "saica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAICA"])},
    "saicaCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva SAICA"])},
    "saicaEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar SAICA"])},
    "saihCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva SAIH"])},
    "saihEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar SAIH"])},
    "statisticalMetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métrica estadística"])},
    "opendataAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de estaciones OpenData"])},
    "opendata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Data"])},
    "opendataAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las estaciones OpenData"])},
    "reportParameterList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe parámetros"])},
    "refLab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medidas de referencia del laboratorio"])},
    "river": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Río"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última semana"])},
    "last3months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 3 meses"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último mes"])},
    "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitud"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud"])},
    "pluviometry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pluviometría"])},
    "pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presión"])},
    "roomTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura ambiente"])},
    "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viento"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estaciones"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oficina"])},
    "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir imagen"])},
    "prediction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predicción"])},
    "magnitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnitud"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
    "validateData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación de datos"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta"])},
    "filterBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar filtros de búsqueda"])},
    "searchValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por valor"])},
    "showIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar en"])},
    "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfica"])},
    "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla"])},
    "uploadedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos importados"])},
    "upload": {
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación"])},
      "integratedSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras integradas"])},
      "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornos"])},
      "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertidos"])},
      "parameterCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Códigos de los parámetros"])}
    },
    "ourEstimations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimaciones Aquacorp"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
    "noStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin estación"])},
    "estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimación"])},
    "difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desviación media"])},
    "valueMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máximo"])},
    "valueMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mínimo"])},
    "valueAverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor medio"])},
    "numEstimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº estimaciones"])},
    "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentil"])},
    "sur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SuR 20%"])},
    "noProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin procesar"])},
    "relative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relativas"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual"])},
    "trend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencia"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia"])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalos"])},
    "exposure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposición"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noche"])},
    "all_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo el día"])},
    "exposure_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esp. Día"])},
    "exposure_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esp. Noche"])},
    "exposure_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esp. ATA/AMA"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición del filtro (º)"])},
    "range_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutos antes"])},
    "range_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutos despues"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
    "infoEstimations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacion de las estimaciones"])},
    "ranges": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0, 10]"])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0, > 10]"])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0, >150]"])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[400-700] azul:[400-490] verde:[500-599] rojo[600-700]"])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0 y >200]"])}
      }
    },
    "measurement_units": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mg/g"])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mg/g"])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nm"])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mg/m3"])}
      }
    },
    "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruto"])},
    "clean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpio"])},
    "lastEstimations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos valores para calcular estimaciones: "])},
    "created_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos brutos Aquacorp"])},
    "samplingPointType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de punto de monitorización"])},
    "intervalEstimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de las estimaciones:"])},
    "configMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración menu"])},
    "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar"])},
    "uploadParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros subidos"])},
    "estimationParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros estimaciones"])},
    "availableRoutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rutas disponibles"])},
    "variation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variación"])},
    "statisticalAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis estadístico"])},
    "parameterRanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangos del parámetro"])},
    "temporalStatisticalValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valores estadísticos temporales en el periodo seleccionado"])},
    "statisticalValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valores estadísticos en el periodo seleccionado"])},
    "nameRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de rango"])},
    "beginningRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de rango"])},
    "endRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de rango"])},
    "temporalAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis temporal en el periodo seleccionado"])},
    "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral"])},
    "periodStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha inicio periodo"])},
    "periodEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha fin periodo"])},
    "showResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar resultados"])},
    "graphAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar resultados"])},
    "numericAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar resultados"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
    "minAreaPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Área minima"])},
    "minPixelPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Mínimo pixeles"])},
    "whiteThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral blancas"])},
    "blackThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral negras"])},
    "allParamsEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar evento a todos los parámetros"])}
  },
  "text": {
    "parameterTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización tiene todos los parámetros."])},
    "parameterClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametros importables por los clientes"])},
    "cameraThroughModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica que cámaras pasan por el modelo"])},
    "parameterFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización no tiene todos los parámetros:"])},
    "opendataTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización tiene todas las estaciones OpenData"])},
    "opendataFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización no tiene todas las estaciones OpenData"])},
    "noDataStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta estación no ha enviado datos."])},
    "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura manual de una imagen en un río."])},
    "uploadTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La imagen tiene que ser en jpg/png con un tamaño superior a 2592x1944"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de las alertas para los puntos de monitorización"])},
    "alertStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de las alertas para las estaciones"])},
    "generatedAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de alertas"])},
    "menuItemsconfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuracion partes del menu visible para el usuario"])},
    "reportService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de generación de informes"])},
    "galleryService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de galeria"])}
  },
  "placeholder": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "typeParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operacional,ambiental, calidad de agua..."])},
    "selectImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un punto de monitorización"])}
  },
  "status": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activo"])},
    "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no activo"])}
  },
  "btn": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "accountConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la cuenta"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
    "deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "changeViewModeList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista de lista"])},
    "changeViewModeMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista de mapa"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESACTIVADA"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "btn24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 horas"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar todas"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar archivo"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar fecha"])},
    "uploadToServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar"])},
    "visualize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar"])},
    "uploadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar"])},
    "cleanningAutomatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpieza automática"])},
    "dataTrainingPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa Datos Entrenamiento"])},
    "manualPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa manual"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "captureImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturar imagen"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar"])},
    "satelital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos satelitales"])},
    "downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar CSV "])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "cleanningAndUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar y subir"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar"])},
    "sendCleanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir limpieza"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estaciones"])},
    "generateZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar zip"])},
    "downloadZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar zip"])},
    "graficar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graficar"])},
    "graphData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a \"Servicio de graficación\""])}
  },
  "radio": {
    "cleaningprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de limpieza "])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automática"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
  },
  "error": {
    "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error."])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obligatorio."])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo debe tener al menos ", _interpolate(_named("min")), " caracteres."])},
    "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])},
    "ACCESS_DENIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso denegado."])},
    "LOGIN_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario o contraseña incorrecto."])},
    "FORM_VALIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay campos no válidos."])},
    "FORM_DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos duplicados."])},
    "SESION_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sesión ha caducado."])},
    "PASS_DONTMATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden."])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos duplicados."])},
    "pass_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una contraseña más larga"])},
    "NO_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ninguna alerta guardada"])},
    "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La alerta no tiene nombre"])},
    "no_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La alerta no tiene descripción"])},
    "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formato de la fecha no es corecto"])},
    "loadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido cargar los datos"])},
    "notFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un archivo primero"])},
    "sizeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo demasiado pesado"])},
    "notParameter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El parámetro \"", _interpolate(_named("name")), "\" no se reconoce."])},
    "notRangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona primero un rango de fechas"])},
    "notInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de fechas del archivo no corresponde al seleccionado"])},
    "wrongDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe el rango de fechas."])},
    "dateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de fechas no debe ser superior a 4 días"])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El parámetro seleccionado no se encuentra en el archivo."])},
    "paramsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado los siguientes parámetros en el sistema: "])},
    "invalidLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ubicación del dispositivo no coincide con la del punto de monitorización."])},
    "no_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un número"])},
    "noDataDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos en las fechas seleccionadas"])},
    "notUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pueden subir los datos"])}
  },
  "success": {
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha cargado con éxito"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación realizada con éxito."])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión iniciada."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión finalizada."])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha guardado con éxito"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha borrado con éxito"])}
  },
  "warning": {
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicado"])},
    "notData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos"])},
    "operSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay selección de operadores"])},
    "notSelectedParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona primero un parámetro"])},
    "stationOccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La estación ya tiene un punto de monitorización asignado"])},
    "notImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay imágenes"])},
    "notNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay avisos"])},
    "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pueden configurar más de tres cámaras"])},
    "confNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta configuración no está permitida"])},
    "confProcessingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede haber dos cámaras configuradas para el mismo periodo"])},
    "confNotCorrectTime": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No has seleccionado el periodo para la cámara \"", _interpolate(_named("code")), "\""])}
  },
  "confirm": {
    "activateContainer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El contenedor <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateContainer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El contenedor <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "activateUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El usuario <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El usuario <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "activateStation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La estación <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateStation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La estación <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "activateSamplingPoint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El punto de monitorización <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateSamplingPoint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El punto de monitorización <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "deleteAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta alerta y sus notificaciones serán borradas. ¿Desea continuar?"])},
    "activateAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La alerta <b>", _interpolate(_named("name")), "</b> será activada<br/>¿Desea continuar?"])},
    "deactivateAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La alerta <b>", _interpolate(_named("name")), "</b> será desactivada<br/>¿Desea continuar?"])},
    "activateParameter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El parámetro <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateParameter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El parámetro <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "deleteGeneratedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este aviso será borrado<br/>¿Desea continuar?"])}
  },
  "info": {
    "infoUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se van a importar los siguientes parámetros encontrados en el archivo."])},
    "locationRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe permitir utilizar la ubicación del dispositivo"])},
    "cameraRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe permitir utilizar la camara del dispositivo"])},
    "notificationNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("number")), " alertas en los últimos ", _interpolate(_named("minutes")), " minutos"])},
    "samplingPointsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha encontrado ningún punto de monitorización."])},
    "stationsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha encontrado ningúna estación."])},
    "calculateTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cálculo de la tendencia se lleva a cabo mediante la obtención de la media de los valores más recientes y su comparación con el último valor:"])},
    "upwardTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el último valor es superior a la media, la tendencia está subiendo 🡩."])},
    "downwardTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el último valor es inferior a la media, la tendencia está bajando 🡫."])},
    "flatTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el último valor es igual a la media, la tendencia se mantiene 🡪."])},
    "param": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace referencia a la profundidad del disco de Secchi, un parámetro asociado a la claridad del agua, ya que cuanto mayor sea su valor, más clara es. Valores altos de claridad, pueden relacionarse con valores bajos de TU."])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace referencia a la concentración de materia orgánica con color disuelta en el agua. Cuanto mayor sea, el agua presentará un color más oscuro."])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta variable está vinculada con el grado de turbidez del agua. Cuanto más alta sea la concentración de sólidos en suspensión, mayor será la turbidez."])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace referencia al color predominante del agua. En concreto lo que se mide es la longitud de onda predominante (nm), dentro del rango del visible. El agua fluvial se puede clasificar en 4 colores fundamentales (azul, verde, amarillo y marrón oscuro), cuyas longitudes de onda centrales son 468, 565, 572.5 y 605 nm, respectivamente."])},
      "AVW_visnir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud de onda predominante (nm) del agua, considerando el rango del visible (VIS) y del infrarrojo cercano (NIR). Es un parámetro complementario a AVW_vis, ya que da una idea de la influencia del NIR (que para el agua clara y sin alteraciones, es prácticamente nula)."])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es indicativo de la concentración de clorofila α en el agua del río (grado de eutrofización del agua, ya que es una variable asociada a la cantidad de biomasa algal)."])}
      },
      "Chl_a": {
        "Se2WaQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica la concentración de clorofila a en el agua del río (grado de eutrofización del agua). Se2WaQ hace referencia al algoritmo empleado."])}
      }
    },
    "trend": {
      "DOWNWARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↓"])},
      "UPWARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↑"])},
      "FLAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["→"])}
    },
    "samplingPointForm_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establece cada cuanto se quiere mostrar una estimación en la plataforma. Si el campo es 0 indica que se está usando el intervalo predeterminado de la estación. "])}
  },
  "app": {
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de apoyo a la toma de decisiones"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de gestión de estaciones"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramienta de administración"])}
  },
  "magnitude": {
    "GPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS"])},
    "voltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batería"])},
    "time_batery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo estimado de carga"])},
    "percentage_batery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje batería"])},
    "stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corriente media consumida"])},
    "humedity_int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humedad interior"])},
    "temp_int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura interior"])},
    "available_SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacio SD"])},
    "optical_operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcionamiento óptico"])},
    "rssi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "log_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log_present"])},
    "state_SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado SD"])},
    "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado en la SD"])},
    "luminosity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminosidad"])},
    "temp_ext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura exterior"])},
    "temp_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura del agua"])},
    "atmospheric_pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presión atmosférica"])},
    "humidity_ext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humedad exterior"])},
    "distance_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia del agua"])},
    "angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo"])},
    "conductivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductividad"])},
    "turbidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turbidez"])},
    "sst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos en suspensión"])},
    "ammonium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amonio:"])},
    "550nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (550nm)"])},
    "410nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (410nm)"])},
    "440nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (440nm)"])},
    "470nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (470nm)"])},
    "510nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (510nm)"])},
    "583nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (583nm)"])},
    "620nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (620nm)"])},
    "670nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (670nm)"])},
    "CLEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (CLEAR)"])},
    "NIR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espectrometro (Multiespectral NIR)"])},
    "luminosity_sky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminosidad del cielo"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])},
    "level_station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel estación"])},
    "iccid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iccid"])}
  },
  "format": {
    "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY HH:mm:ss"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY"])}
  },
  "parameterType": {
    "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estación"])},
    "samplingpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de monitorización"])},
    "operational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacional"])},
    "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiental"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calidad"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "meteorology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos meteorológicos"])},
    "data_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de imagen"])},
    "floating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flotante"])},
    "tmoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TMOA"])}
  },
  "tooltip": {
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer filtros"])}
  },
  "enum": {
    "fields": {
      "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "STATION_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de estación"])},
      "STATION_STATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de estación"])},
      "SAMPLINGPOINT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre punto de monitorización"])},
      "SAMPLINGPOINT_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código punto de monitorización"])},
      "SAMPLINGPOINT_RIVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Río punto de monitorización"])},
      "ALERT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la alerta"])}
    },
    "params": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claridad"])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materia orgánica disuelta"])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos en suspensión"])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color del agua"])},
      "AVW_visnir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ColorIR"])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clorofila α"])}
      },
      "Chl_a": {
        "Se2WaQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clorofila 1"])}
      }
    },
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer filtros"])},
    "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graficar"])}
  },
  "message": {
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se debe completar el formulario para poder visualizar el informe."])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se debe completar el formulario para poder visualizar la galeria."])}
  },
  "camera": {
    "brightness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brillo"])},
    "contrast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraste"])},
    "saturation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturación"])},
    "hue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hue"])},
    "autoWhiteBalanceEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance de blancos automático"])},
    "manualWhiteBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
    "gamma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamma"])},
    "gain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancia"])},
    "sharpeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sharpeness"])},
    "autoExposure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposición automática"])},
    "intervals_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalos"])},
    "intervals": {
      "interval1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo 1"])},
      "interval2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo 2"])},
      "interval3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo 3"])},
      "interval4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo 4"])}
    },
    "exposures_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposiciones"])},
    "exposures": {
      "exposure1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposición 1"])},
      "exposure2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposición 2"])},
      "exposure3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposición 3"])},
      "exposure4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposición 4"])},
      "exposure5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposición 5"])}
    },
    "filterPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición del filtro"])}
  },
  "info_tmoa": {
    "title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de las matrices de agua"])},
    "tmoa_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribución de matrices de agua"])},
    "num_optical_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de datos ópticos"])},
    "percentage_optical_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de datos ópticos"])},
    "num_appearances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de apariciones"])},
    "percentage_appearances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de apariciones"])},
    "frequency_appearances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de apariciones"])},
    "tmoa_duration_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis duración de matrices de agua"])},
    "min_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración minima"])},
    "max_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración máxima"])},
    "average_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración mediana"])},
    "median_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración mediana"])},
    "tmoa_transitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transiciones de matrices de agua"])},
    "tmoa_distribution_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribución temporal de matrices de agua según horas"])},
    "from_0_to_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 0 a 2"])},
    "from_2_to_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 2 a 4"])},
    "from_4_to_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 4 a 6"])},
    "from_6_to_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 6 a 8"])},
    "from_8_to_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 8 a 10"])},
    "from_10_to_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 10 a 12"])},
    "from_12_to_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 12 a 14"])},
    "from_14_to_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 14 a 16"])},
    "from_16_to_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 16 a 18"])},
    "from_18_to_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 18 a 20"])},
    "from_20_to_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 20 a 22"])},
    "from_22_to_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 22 a 24"])},
    "tmoa_distribution_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribución temporal de matrices de agua según días"])}
  },
  "day": {
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martes"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miércoles"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jueves"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viernes"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])}
  }
}
export default resource