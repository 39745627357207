import { SamplingPoint, SamplingPointLabelling } from "@/types/samplingpoint.type";
import { Parameter } from "./../types/parameter.type";
import { Filter, OrderByCondition } from "./../types/filter.type";
import { Pagination } from "./../types/pagination.type";
import { responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";
import { InfoEstimationYesterday } from "@/types/infoEstimationYesterday";
import { UploadHistory } from "@/types/uploadHistory.type";

/**
 * get samplingPoints
 *
 * @param page
 * @param size
 * @param filter
 * @returns
 */
export const getSamplingPoints = (
  page?: number,
  size?: number,
  filter?: Filter,
  order?: OrderByCondition
): Promise<Pagination<SamplingPoint>> => {
  const params: { page?: number; size?: number; filter?: Filter; order?: OrderByCondition } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }
  if (order) {
    params.order = order;
  }

  return axios.get(`/samplingpoint/`, { params }).then(responseHandlerDefault);
};
/**
 * get samplingPoints
 *
 * @param page
 * @param size
 * @param filter
 * @returns
 */
export const getSamplingPointsList = (
  page?: number,
  size?: number,
  filter?: Filter,
  order?: OrderByCondition
): Promise<Pagination<SamplingPoint>> => {
  const params: { page?: number; size?: number; filter?: Filter; order?: OrderByCondition } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }
  if (order) {
    params.order = order;
  }

  return axios.get(`/samplingpoint/list`, { params }).then(responseHandlerDefault);
};
/**
 * get samplingPoints without relations
 *
 * @param page
 * @param size
 * @param filter
 * @returns
 */
export const getSamplingPointsNoRelations = (
  page?: number,
  size?: number,
  filter?: Filter,
  order?: OrderByCondition
): Promise<Pagination<SamplingPoint>> => {
  const params: { page?: number; size?: number; filter?: Filter; order?: OrderByCondition } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }
  if (order) {
    params.order = order;
  }

  return axios.get(`/samplingpoint/noRelations`, { params }).then(responseHandlerDefault);
};
/**
 * get samplingPoints with container
 *
 * @param page
 * @param size
 * @param filter
 * @returns
 */

/**
 * get all sampling points
 * @returns
 */
export const getAllSamplingPoints = (): Promise<SamplingPoint[]> => {
  return axios.get<Pagination<SamplingPoint>>(`/samplingpoint/`).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    throw new Error(res.status.toString());
  });
};

export const getContainerSamplingPoints = (containerId: number): Promise<SamplingPoint[]> => {
  return axios.get<Pagination<SamplingPoint>>(`/samplingpoint/`).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    throw new Error(res.status.toString());
  });
};

export const postSamplingPoint = (samplingPoint: SamplingPoint): Promise<SamplingPoint> => {
  return axios
    .post<SamplingPoint>("/samplingpoint/new", samplingPoint)
    .then(responseHandlerDefault);
};

export const postSamplingPointLabelling = (
  samplingPointLabelling: SamplingPointLabelling
): Promise<SamplingPointLabelling> => {
  return axios
    .post<SamplingPointLabelling>("/samplingpoint/labelling", samplingPointLabelling)
    .then(responseHandlerDefault);
};

export const getSamplingPointLabelling = (
  samplingPointId: number
): Promise<SamplingPointLabelling> => {
  return axios
    .get<SamplingPointLabelling>(`/samplingpoint/labelling/${samplingPointId}`)
    .then(responseHandlerDefault);
};

export const putSamplingPoint = (samplingPoint: SamplingPoint): Promise<SamplingPoint> => {
  return axios
    .put(`/samplingpoint/${samplingPoint.id}`, samplingPoint)
    .then(responseHandlerDefault);
};

export const getSamplingPointById = (samplingPointId: number): Promise<SamplingPoint> => {
  return axios.get<SamplingPoint>(`/samplingpoint/${samplingPointId}`).then(responseHandlerDefault);
};

export const getSamplingPointByName = (samplingPointName: string): Promise<SamplingPoint> => {
  return axios
    .get<SamplingPoint>(`/samplingpoint/sampointParams/${samplingPointName}`)
    .then(responseHandlerDefault);
};

/**
 * set samplingpoint status
 * @param id
 * @param active
 * @returns
 */
export const setSamplingPointStatus = (id: number, active: boolean): Promise<SamplingPoint> => {
  return axios
    .post<SamplingPoint>(`/samplingpoint/${id}/${active ? "activate" : "deactivate"}`)
    .then(responseHandlerDefault);
};

export const getSamplingPointNames = (): Promise<SamplingPoint[]> => {
  return axios.get<SamplingPoint[]>("/samplingpoint/names").then(responseHandlerDefault);
};

/**
 * get samplingPoints for MapView
 */
export const getCoordinates = (): Promise<SamplingPoint[]> => {
  return axios.get<SamplingPoint[]>("/samplingpoint/coordinates").then(responseHandlerDefault);
};
/**
 * get parameters of sampling point
 */
export const getSamPointOnlyParameters = (samplingPointId: number): Promise<Parameter[]> => {
  return axios
    .get(`/samplingpoint/samPointOnlyParameters/${samplingPointId}`, {
      params: {
        samplingPointId: samplingPointId,
      },
    })
    .then(responseHandlerDefault);
};
export const samPointOnlyParametersClient = (samplingPointId: number): Promise<Parameter[]> => {
  return axios
    .get(`/samplingpoint/samPointOnlyParametersClient/${samplingPointId}`, {
      params: {
        samplingPointId: samplingPointId,
      },
    })
    .then(responseHandlerDefault);
};
export const getsamPointInfoEstimation = (
  samplingPointId: number,
  startDate: Date,
  endDate: Date
): Promise<InfoEstimationYesterday[]> => {
  const params = {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
  return axios
    .get(`samplingpoint/samPointInfoEstimation/${samplingPointId}`, { params })
    .then(responseHandlerDefault);
};

export const getUploadHistory = (id: number): Promise<UploadHistory[]> => {
  return axios.get(`/samplingpoint/uploadHistory/${id}`).then(responseHandlerDefault);
};

export async function createUploadHistory(data: UploadHistory) {
  return axios
    .post<UploadHistory>("/samplingpoint/createUploadHistory", data)
    .then(responseHandlerDefault);
}

export const editUploadHistoryDescription = (data: { id: number; description: string }) => {
  return axios
    .post<UploadHistory>("/samplingpoint/editUploadHistoryDescription", data)
    .then(responseHandlerDefault);
};
