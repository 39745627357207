
import securityService from "@/service/security.service";
import { RootState } from "@/types/state.type";
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import UserPassForm from "@/components/users/UserPassForm.component.vue";
import { getUserByEmail, updateUserPassword } from "@/service/user.service";
import { ElMessage } from "element-plus";
import { getSamplingPointById } from "@/service/samplingpoint.service";

export default defineComponent({
  components: {
    UserPassForm,
  },
  // props: {
  //   collapsable: {
  //     type: Boolean,
  //     default: true,
  //   },
  // },
  data() {
    return {
      samplingPointName: undefined as string | undefined,
    };
  },
  setup() {
    const store = useStore<RootState>();
    const router = useRouter();
    const app = computed(() => securityService.getApp());
    const notificationCss = ref("");
    const firstTime = true;
    const showChangePasswordModal = ref(false);
    const entorno = false;
    const userData: { id?: number; password?: string } = { id: 0, password: "" };
    return {
      newAlert: false,
      router,
      store,
      app,
      notificationCss,
      firstTime,
      showChangePasswordModal,
      entorno,
      userData,
    };
  },
  async mounted() {
    // if (document.body.clientWidth < 1500) {
    //   this.collapseChage();
    // }
    this.firstTime = false;
    this.entorno = process.env.NODE_ENV === "pre" || process.env.NODE_ENV === "development";
    await this.getSamplingPointId();
  },

  watch: {
    notifications: {
      immediate: true,
      handler(newVal) {
        if (this.firstTime && !newVal) {
          return;
        }
        this.notificationCss = "shake";
        setTimeout(() => {
          this.notificationCss = "";
        }, 2000);
      },
    },

    "$route.query.samplingPoint": async function (newSamplingPointId) {
      if (newSamplingPointId) {
        await this.getSamplingPointId();
      } else {
        this.samplingPointName = undefined;
      }
    },
  },
  computed: {
    // collapse(): boolean {
    //   return this.store.state.ui.sidebarOpen;
    // },
    username(): string {
      return this.store.state.auth.user?.email || "";
    },
    notifications(): number | null {
      return this.store.state.ui.notifications;
    },
  },
  methods: {
    handleCommand(command: string) {
      if (command === "loginout") {
        this.store.dispatch("auth/logout").then(() => {
          localStorage.removeItem("user");
          this.router.push("/login");
        });
      }
    },
    // collapseChage(): void {
    //   this.store.commit("ui/sidebarToggle");
    // },
    goToAlerts() {
      if (this.$route.path.includes("admin")) {
        this.$router.push("/admin/generatedAlerts");
      } else if (this.$route.path.includes("stations")) {
        this.$router.push("/stations/generatedAlerts");
      } else {
        this.$router.push("/support/generatedAlerts");
      }
      this.newAlert = false;
    },
    async changeUserPassword() {
      //$2b$08$dWYIlSpSiC24WuxBVPgl6eCM1yYPI8iYwK1FomRRKXG4bkOxLGoWK
      this.userData = await getUserByEmail(this.store.state.auth.user?.email);
      try {
        const userPassForm = this.$refs.userPassForm as InstanceType<typeof UserPassForm>;
        if (userPassForm) {
          updateUserPassword(this.userData.id, await userPassForm.changePass());
          ElMessage.success(this.$t("Constraseña actualizada con éxito"));
          this.showChangePasswordModal = false;
        } else {
          console.error("Error changing password.");
        }
      } catch (error) {
        console.error("Error changing password:", error);
      }
    },

    async getSamplingPointId() {
      if (this.$route.query.samplingPoint) {
        const response = await getSamplingPointById(
          parseInt(this.$route.query.samplingPoint as string)
        );
        this.samplingPointName = response.name;
      } else {
        this.samplingPointName = undefined;
      }
    },
  },
});
